<template>
  <div class="flex flex-col">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div class="inline-block min-w-full mb-6 overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="min-w-full">
          <thead>
            <tr>
              <th class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                OAuth Clients
              </th>
              <th class="px-6 py-3 bg-gray-200 border-b border-gray-200" />
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-if="tokens.length < 1">
              <td
                colspan="2"
                class="py-4 pl-4 text-sm border-b border-gray-200 whitespace-nowrap"
              >
                You have no OAuth Clients connected
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="min-w-full">
          <thead>
            <tr>
              <th class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                API Access Tokens
              </th>
              <th class="px-4 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-600 uppercase bg-gray-200 border-b border-gray-200">
                Last Active
              </th>
              <th class="px-2 py-3 bg-gray-200 border-b border-gray-200" />
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              v-for="token in tokens"
              :key="token.id"
            >
              <td class="py-4 pl-4 border-b border-gray-200 whitespace-nowrap">
                {{ token.name }}
              </td>
              <td>
                {{ token.last_active }}
              </td>
              <td>
                <fa-icon
                  icon="ellipsis"
                  class="text-gray-700"
                />
              </td>
            </tr>

            <tr v-if="tokens.length < 1">
              <td
                colspan="3"
                class="py-4 pl-4 text-sm border-b border-gray-200 whitespace-nowrap"
              >
                You have no api tokens active.
              </td>
            </tr>
          </tbody>
          <tfoot class="bg-white">
            <tr
              v-if="!showCreateTokenForm"
              class="border-b border-gray-200 whitespace-nowrap"
            >
              <td
                colspan="3"
                class="py-4 pl-4"
              >
                <a
                  href="#"
                  class="text-blue-500 underline hover:cursor-pointer"
                  @click.prevent="showCreateForm"
                >+ Create a new access token</a>
              </td>
            </tr>

            <tr
              v-show="showCreateTokenForm"
              class="border-b border-gray-200 whitespace-nowrap"
            >
              <td
                colspan="3"
                class="py-2 pl-4"
              >
                <div class="flex">
                  <form
                    class="flex"
                    @submit.prevent="onCreateToken"
                  >
                    <div class="relative flex items-stretch focus-within:z-10">
                      <input
                        id="tokenName"
                        ref="nameinput"
                        type="text"
                        name="tokenName"
                        class="block w-full border border-gray-300 rounded-none min-w-1/2 focus:ring-indigo-500 focus:border-indigo-500 rounded-l-md sm:text-sm"
                        placeholder="Token Name"
                      >
                    </div>
                    <button
                      class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-white bg-green-500 border border-green-500 rounded-r-md hover:bg-green-600 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      @click.prevent="onCreateToken"
                    >
                      <span>Create</span>
                    </button>

                    <button
                      class="relative inline-flex items-center px-4 py-2 mx-2 space-x-2 text-sm font-medium text-gray-700 rounded-md hover:bg-gray-200"
                      @click.prevent="clearCreateForm"
                    >
                      <span>Cancel</span>
                    </button>

                    <div
                      v-if="createForm.busy"
                      class="relative inline-flex"
                    >
                      <fa-icon
                        icon="spinner"
                        class="text-gray-500"
                        spin
                      /> Working
                    </div>
                  </form>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const TeamTierBadge = () => import('@components/badges/TeamTierBadge')

export default {
  name: 'ProfileApi',

  components: { TeamTierBadge },

  data () {
    return {
      showCreateTokenForm: false,

      createForm: new SparkForm({
        name: ''
      })
    }
  },

  computed: {

    tokens () {
      return []
    },

    teams () {
      return this.$store.getters['teams/teams']
    },

    user () {
      return this.$store.getters.user
    }
  },

  methods: {

    showCreateForm () {
      this.showCreateTokenForm = true
      this.createForm.name = ''
      this.$refs.nameinput.focus()
    },

    clearCreateForm () {
      this.showCreateTokenForm = false
      this.createForm.name = ''
    },

    onCreateToken () {
      this.createForm.startProcessing()

      // this.$store
      //   .dispatch("comments/addComment", {
      //     resourceId: this.resourceId,
      //     payload: payload,
      //   })
      //   .then(result => {
      //     this.createForm.finishProcessing();
      //     this.showSuccess()
      //   })
      //   .catch((response) => {
      //     console.log(response);
      //     alert.error("Failed to create token");
      //     this.formFormHasErrors = true;
      //     this.createForm.setErrors(response.data);
      //     this.createForm.finishProcessing();
      //   });
    }

  }
}
</script>
